import {createSelector} from 'reselect'
import isEqual from 'lodash/isEqual'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import isNumber from 'lodash/isNumber'
import isString from 'lodash/isString'
import isBefore from 'date-fns/isBefore'
import subDays from 'date-fns/subDays'
import isAfter from 'date-fns/isAfter'
import addDays from 'date-fns/addDays'

import {
  STARTER_PLAN,
  BASIC_PLAN,
  PRO_PLAN,
  ENTERPRISE_PLAN,
  ESSENTIALS_PLAN,
  ADVANCED_PLAN,
  PREMIUM_PLAN,
  NEW_PLANS,
  APPS_PLAN,
} from '../common/constants/Plans.js'
import {currentDateSelector} from '../ordoro/CurrentDateListener.js'
import {
  getState,
  dispatch,
  setForm,
  formsSelector,
  updateForm,
} from '../store.js'
import api from '../common/api.js'
import {handlePollingError} from '../redux/actions/ui/index.js'
import {defaultWarehouseSelector} from '../data/warehouses.js'
import STATES from '../common/usStates.json'
import {canBeTrustedSelector} from '../data/trust.js'
import {uiSelector} from '../redux/selectors/ui/index.js'
import {isPositiveNumeric} from '../common/utils.js'
import datadogRum from '../common/analytics/datadogRum.js'
import {
  APP_PRICES,
  DROPSHIP_APP_ADVANCED,
  DROPSHIP_APP_PREMIUM,
  INVENTORY_APP_ADVANCED,
  INVENTORY_APP_PREMIUM,
  SHIP_APP_ADVANCED,
  SHIP_APP_ESSENTIALS,
  SHIP_APP_PREMIUM,
} from '../common/constants/Apps.js'
import userflow from '../common/analytics/userflow.js'
import {defaultPaymentAccountSelector} from './paymentAccounts.js'

export const COMPANY = 'COMPANY'

function createFeatureSelector(featureName, featuresSelector, ...funcs) {
  const endSelector = funcs.pop()

  return createSelector(featuresSelector, ...funcs, (features, ...args) => {
    const featureValue = endSelector(features[featureName], features, ...args)

    datadogRum.addFeatureFlagEvaluation(featureName, featureValue)

    return featureValue
  })
}

function defaultFeatureFunc(value) {
  return !!value
}

export function companySelector(state) {
  return formsSelector(state)[COMPANY] || companySelector.default
}
companySelector.default = window.ORDORO_BOOTSTRAP.company

export function setCompany(company) {
  setForm(COMPANY, company)

  datadogRum.setUserProperty('plan', company.plan)
  datadogRum.setUserProperty('features', company.features)

  userflow.updateGroup({
    company_name: company.name,
    // disabling until we might support groups (see userflow.js)
    // name: company.name,
    plan: company.plan,
    apps: [
      shipAppSelector(getState()),
      dropshipAppSelector(getState()),
      inventoryAppSelector(getState()),
    ].filter((v) => v),
    has_credit_card: hasCreditCardSelector(getState()),
    has_account_credit_card: hasAccountCreditCardSelector(getState()),
    ...Object.entries(company.features).reduce((prev, [key, value]) => {
      if (value === true || isString(value) || isNumber(value)) {
        prev[`feature__${key}`] = value
      }

      return prev
    }, {}),
  })
}

export function loadCompany() {
  setCompany(window.ORDORO_BOOTSTRAP.company)
}

export function updateCompany(updates) {
  updateForm(COMPANY, updates)
}

export function updateFeatures(updates) {
  const company = companySelector(getState())

  updateCompany({features: {...company.features, ...updates}})
}

export const companyIDSelector = createSelector(companySelector, ({id}) => id)

export const companyNameSelector = createSelector(
  companySelector,
  ({name}) => name,
)

export const companyContactSelector = createSelector(
  companySelector,
  ({contact}) => contact,
)

export function companyFeaturesSelector(state) {
  const company = companySelector(state)

  return company.features || companyFeaturesSelector.default
}
companyFeaturesSelector.default = {}

export const currencySymbolSelector = createSelector(
  companySelector,
  (company) => company && company.currency_symbol,
)

export function createdDateSelector(state) {
  return companySelector(state).created
}

export function activatedDateSelector(state) {
  return companySelector(state).activated
}

export const deactivatedDateSelector = (state) =>
  companySelector(state).deactivated

// We are okay with locking current date since this selector is only used on page load
export const canNotReactivateSelector = createSelector(
  deactivatedDateSelector,
  (deactivated) =>
    !!deactivated && isBefore(new Date(deactivated), subDays(new Date(), 30)),
)

export const isDeactivatedSelector = createSelector(
  deactivatedDateSelector,
  (deactivated) => !!deactivated,
)

export function hasCancellationReasonSelector(state) {
  return !!companySelector(state).cancellation_reason
}

export const planSelector = createSelector(companySelector, ({plan}) => plan)

const newPlansDate = new Date('2023-01-03T06:00:00.000Z')
export const isNewPlansSelector = createSelector(
  createdDateSelector,
  (activatedDate) => isBefore(newPlansDate, new Date(activatedDate)),
)

export const usesAppsSelector = createSelector(
  planSelector,
  (plan) => plan === APPS_PLAN,
)

export const newPlanSelector = createSelector(
  planSelector,
  sourceSelector,
  (plan, source) =>
    NEW_PLANS.includes(source.new_plan)
      ? source.new_plan
      : [STARTER_PLAN, BASIC_PLAN].includes(plan)
      ? ESSENTIALS_PLAN
      : [PRO_PLAN, ENTERPRISE_PLAN].includes(plan)
      ? PREMIUM_PLAN
      : ADVANCED_PLAN,
)

export function canInputPromoCodeSelector(state) {
  const usePromoCode = false // turning this off for now
  const hasCreditCard = hasCreditCardSelector(state)

  return usePromoCode && !hasCreditCard
}

export const printNodeConfigSelector = createSelector(
  companySelector,
  (company) => company.print_node || {},
)

export const printNodeAPIKeySelector = createSelector(
  printNodeConfigSelector,
  (config) => config.api_key,
)

export const hasPrintNodeAPIKeySelector = createSelector(
  printNodeAPIKeySelector,
  (apiKey) => !!apiKey,
)

export const useDropshippingSelector = createFeatureSelector(
  'use_dropshipping',
  companyFeaturesSelector,
  planSelector,
  usesAppsSelector,
  (value, features, plan, usesApps) =>
    usesApps ? !!value : [PRO_PLAN, ENTERPRISE_PLAN].includes(plan),
)

export const usesInventorySelector = createFeatureSelector(
  'use_inventory',
  companyFeaturesSelector,
  planSelector,
  usesAppsSelector,
  (value, features, plan, usesApps) =>
    usesApps ? !!value : [PRO_PLAN, ENTERPRISE_PLAN].includes(plan),
)

export const useKittingSelector = createFeatureSelector(
  'use_kitting',
  companyFeaturesSelector,
  usesInventorySelector,
  usesAppsSelector,
  (value, features, usesInventory, usesApps) =>
    usesApps ? !!value : usesInventory,
)

export const usePurchaseOrdersSelector = createFeatureSelector(
  'use_purchase_orders',
  companyFeaturesSelector,
  usesInventorySelector,
  usesAppsSelector,
  (value, features, usesInventory, usesApps) =>
    usesApps ? !!value : usesInventory,
)

export const showFeatureLocksSelector = createFeatureSelector(
  'show_feature_locks',
  companyFeaturesSelector,
  defaultFeatureFunc,
)

export const useRulesSelector = createFeatureSelector(
  'use_rules',
  companyFeaturesSelector,
  planSelector,
  usesAppsSelector,
  (value, features, plan, usesApps) =>
    usesApps ? !!value : plan !== STARTER_PLAN,
)

export const showRulesNavSelector = createSelector(
  useRulesSelector,
  showFeatureLocksSelector,
  (useRules, showFeatureLocks) => useRules || showFeatureLocks,
)

export const showSuppliersNavSelector = createSelector(
  usesInventorySelector,
  showFeatureLocksSelector,
  (usesInventory, showFeatureLocks) => usesInventory || showFeatureLocks,
)

export const usePresetsSelector = createFeatureSelector(
  'use_presets',
  companyFeaturesSelector,
  planSelector,
  usesAppsSelector,
  (value, features, plan, usesApps) =>
    usesApps ? !!value : plan !== STARTER_PLAN,
)

export function appMessageSelector(state) {
  const {zuckSuppressAppMessage} = uiSelector(state)
  const appMessage = companySelector(state).app_message

  return !zuckSuppressAppMessage && !isEmpty(appMessage) ? appMessage : null
}

export const apiLockedSelector = createSelector(
  companySelector,
  (company) => !!company.api_locked,
)

export const canUseLogosOnLabelsSelector = createFeatureSelector(
  'logos_on_labels',
  companyFeaturesSelector,
  defaultFeatureFunc,
)

export function paymentDetailsSelector(state) {
  return (
    companySelector(state).payment_details || paymentDetailsSelector.default
  )
}
paymentDetailsSelector.default = {}

export function stripePaymentDetailsSelector(state) {
  return (
    paymentDetailsSelector(state).stripe || stripePaymentDetailsSelector.default
  )
}
stripePaymentDetailsSelector.default = {}

export function hasCreditCardSelector(state) {
  return Object.keys(stripePaymentDetailsSelector(state)).length !== 0
}

export function stripeCustomerIDSelector(state) {
  return companySelector(state).stripe_customer_id
}

export function netZeroPaymentDetailsSelector(state) {
  return (
    paymentDetailsSelector(state).net_zero ||
    netZeroPaymentDetailsSelector.default
  )
}
netZeroPaymentDetailsSelector.default = {}

export function hasAccountCreditCardSelector(state) {
  const canUsePlaid = canUsePlaidSelector(state)
  const hasStripeCreditCard = hasCreditCardSelector(state)
  const hasNetZeroCreditCard =
    Object.keys(netZeroPaymentDetailsSelector(state)).length !== 0
  const hasPaymentAccount = !!defaultPaymentAccountSelector(state)

  return (
    hasPaymentAccount ||
    hasNetZeroCreditCard ||
    (canUsePlaid ? false : hasStripeCreditCard)
  )
}

export function sourceSelector(state) {
  return get(companySelector(state), 'source') || {}
}

export function isInTrialSelector(state) {
  const {tool} = sourceSelector(state)

  if (
    ['ae_account_creation_tool', 'create_vendor_connect_tool'].includes(tool)
  ) {
    return false
  }

  const currentDate = currentDateSelector(state)
  const trialEndDate = trialEndDateSelector(state)

  if (!trialEndDate) {
    return false
  }

  return isAfter(trialEndDate, currentDate)
}

export function trialEndDateSelector(state) {
  const createdDate = createdDateSelector(state)
  const {trial_expires} = sourceSelector(state)

  if (trial_expires) {
    return new Date(trial_expires)
  }

  return addDays(new Date(createdDate), 15)
}

export function canChangePlanSelector(state) {
  const plan = planSelector(state)
  const isInTrial = isInTrialSelector(state)

  if (plan === ENTERPRISE_PLAN) {
    return false
  }

  if (plan === PRO_PLAN && !isInTrial) {
    return false
  }

  return true
}

export const isVendorConnectSelector = createSelector(
  (state) => companySelector(state).vendor_connect,
  (isVendorConnect) => !!isVendorConnect,
)

export const canIgnoreAmazonPrimeShipperRestrictionSelector = createFeatureSelector(
  'ignore_amazon_prime_shippper_restriction',
  companyFeaturesSelector,
  defaultFeatureFunc,
)

export const canShipAllAmazonWithAmazonSelector = createFeatureSelector(
  'can_ship_all_amazon_with_amazon',
  companyFeaturesSelector,
  defaultFeatureFunc,
)

export const useOrdoroProductNameInAbodePackingListsSelector = createFeatureSelector(
  'abode_packing_line_product_name',
  companyFeaturesSelector,
  defaultFeatureFunc,
)

export const useAbodeEnableEnvelopeSelector = createFeatureSelector(
  'temp_abode_enable_envelope',
  companyFeaturesSelector,
  defaultFeatureFunc,
)

export const canHaveEndiciaInsuranceSelector = createFeatureSelector(
  'show_endicia_insurance',
  companyFeaturesSelector,
  defaultFeatureFunc,
)

export const preferCartOrderLineNamesSelector = createFeatureSelector(
  'prefer_cart_order_line_names',
  companyFeaturesSelector,
  defaultFeatureFunc,
)

export const canUsePitneySelector = createFeatureSelector(
  'use_pitney',
  companyFeaturesSelector,
  defaultFeatureFunc,
)

export const canUsePitneyMerchantSelector = createFeatureSelector(
  'pitney_merchant',
  companyFeaturesSelector,
  defaultFeatureFunc,
)

export const canUseVisibleUSPSSelector = createFeatureSelector(
  'use_visible_usps',
  companyFeaturesSelector,
  defaultFeatureFunc,
)

export const prevalidatedCompanySelector = createFeatureSelector(
  'prevalidated_company',
  companyFeaturesSelector,
  defaultFeatureFunc,
)

export const canUsePackShipSelector = createFeatureSelector(
  'use_pack_ship',
  companyFeaturesSelector,
  defaultFeatureFunc,
)

export const usePermissionsSelector = createFeatureSelector(
  'use_permissions',
  companyFeaturesSelector,
  defaultFeatureFunc,
)

export const usesAPIKeysFeatureFlagSelector = createFeatureSelector(
  'use_api_keys',
  companyFeaturesSelector,
  defaultFeatureFunc,
)

export const canUseAmazonShipperSelector = createFeatureSelector(
  'temp_amazon_shipper',
  companyFeaturesSelector,
  defaultFeatureFunc,
)

export const canTransferInventorySelector = createFeatureSelector(
  'use_inventory_transfers',
  companyFeaturesSelector,
  usesInventorySelector,
  (value, features, usesInventory) => usesInventory && !!value,
)

export const canUseSPSCommerceSelector = createFeatureSelector(
  'temp_use_sps_commerce',
  companyFeaturesSelector,
  defaultFeatureFunc,
)

export const hasUseReturnOrdersFeatureSelector = createFeatureSelector(
  'use_return_orders',
  companyFeaturesSelector,
  defaultFeatureFunc,
)

export const hasUseMOsFeatureSelector = createFeatureSelector(
  'use_manufacturing_orders',
  companyFeaturesSelector,
  defaultFeatureFunc,
)

export const hasUseBatchesFeatureSelector = createFeatureSelector(
  'use_batches',
  companyFeaturesSelector,
  defaultFeatureFunc,
)

export const hasManualDeliverByDateFeatureSelector = createFeatureSelector(
  'has_manual_deliver_by_date',
  companyFeaturesSelector,
  defaultFeatureFunc,
)

export const useTwoPerPageV2Selector = createFeatureSelector(
  'temp_use_two_per_page_v2',
  companyFeaturesSelector,
  defaultFeatureFunc,
)

export const useShopifyLocationAsWarehouseMappedSelector = createFeatureSelector(
  'temp_shopify_location_as_warehouse_mapped',
  companyFeaturesSelector,
  defaultFeatureFunc,
)

export const useInventoryAllocationSelector = createFeatureSelector(
  'inventory_allocation',
  companyFeaturesSelector,
  defaultFeatureFunc,
)

export const usePartialInventoryAllocationSelector = createFeatureSelector(
  'inventory_allocation_partial_allowed',
  companyFeaturesSelector,
  useInventoryAllocationSelector,
  (value, features, useInventoryAllocation) =>
    useInventoryAllocation && !!value,
)

export const canUseShipStationSelector = createFeatureSelector(
  'use_ship_station',
  companyFeaturesSelector,
  defaultFeatureFunc,
)

export const hideUnfinishedSetupSelector = createFeatureSelector(
  'hide_unfinished_setup',
  companyFeaturesSelector,
  defaultFeatureFunc,
)

export const doNotWebsocketLabelInfoSelector = createFeatureSelector(
  'temp_do_not_websocket_label_info',
  companyFeaturesSelector,
  defaultFeatureFunc,
)

export const shopifyNoShopInputSelector = createFeatureSelector(
  'shopify_no_shop_input',
  companyFeaturesSelector,
  defaultFeatureFunc,
)

export const showImgsOrderDetailSelector = createFeatureSelector(
  'show_imgs_order_detail',
  companyFeaturesSelector,
  defaultFeatureFunc,
)

export const showImgsProductListSelector = createFeatureSelector(
  'show_imgs_product_list',
  companyFeaturesSelector,
  defaultFeatureFunc,
)

export const showImgsProductDetailSelector = createFeatureSelector(
  'show_imgs_product_detail',
  companyFeaturesSelector,
  defaultFeatureFunc,
)

export const autoDropshipOnOrderCreationSelector = createFeatureSelector(
  'auto_dropship_on_order_creation',
  companyFeaturesSelector,
  defaultFeatureFunc,
)

export const hasTooManyProductsToHandleSelector = createFeatureSelector(
  'has_too_many_products_to_handle',
  companyFeaturesSelector,
  defaultFeatureFunc,
)

export const useOrdoroShippersSelector = createFeatureSelector(
  'use_ordoro_shippers',
  companyFeaturesSelector,
  usesAppsSelector,
  (value, features, usesApps) => !usesApps || !!value,
)

export const useBYOAShippersSelector = createFeatureSelector(
  'use_byoa_shippers',
  companyFeaturesSelector,
  usesAppsSelector,
  (value, features, usesApps) => !usesApps || !!value,
)

export const canUsePlaidSelector = createFeatureSelector(
  'use_plaid',
  companyFeaturesSelector,
  defaultFeatureFunc,
)

export const isInventoryWritebackDisabledSelector = createFeatureSelector(
  'disable_inventory_writeback',
  companyFeaturesSelector,
  defaultFeatureFunc,
)

export const useInventoryWritebackSelector = createFeatureSelector(
  'use_inventory_writeback',
  companyFeaturesSelector,
  isInventoryWritebackDisabledSelector,
  usesAppsSelector,
  (value, features, isInventoryWritebackDisabled, usesApps) =>
    usesApps ? !!value : !isInventoryWritebackDisabled,
)

export const hasTrialIMMSelector = createSelector(
  usesInventorySelector,
  newPlanSelector,
  useInventoryWritebackSelector,
  (usesInventory, newPlan, useInventoryWriteback) =>
    usesInventory && newPlan === PREMIUM_PLAN && !useInventoryWriteback,
)

export const useShippingLabelsSelector = createFeatureSelector(
  'use_shipping_labels',
  companyFeaturesSelector,
  usesAppsSelector,
  (value, features, usesApps) => !usesApps || !!value,
)

export const useReturnLabelsSelector = createFeatureSelector(
  'use_return_labels',
  companyFeaturesSelector,
  usesAppsSelector,
  (value, features, usesApps) => !usesApps || !!value,
)

export const useRMALabelsSelector = createFeatureSelector(
  'use_rma_labels',
  companyFeaturesSelector,
  usesAppsSelector,
  (value, features, usesApps) => !usesApps || !!value,
)

export const useQuickShipSelector = createFeatureSelector(
  'use_quick_ship',
  companyFeaturesSelector,
  usesAppsSelector,
  (value, features, usesApps) => !usesApps || !!value,
)

export const useScanFormsSelector = createFeatureSelector(
  'use_scan_forms',
  companyFeaturesSelector,
  usesAppsSelector,
  (value, features, usesApps) => !usesApps || !!value,
)

export const useSchedulePickupSelector = createFeatureSelector(
  'use_schedule_pickup',
  companyFeaturesSelector,
  usesAppsSelector,
  (value, features, usesApps) => !usesApps || !!value,
)

export const useManualTrackingSelector = createFeatureSelector(
  'use_manual_tracking',
  companyFeaturesSelector,
  usesAppsSelector,
  (value, features, usesApps) => !usesApps || !!value,
)

export const maxWarehouseCountSelector = createFeatureSelector(
  'max_warehouse_count',
  companyFeaturesSelector,
  usesAppsSelector,
  planSelector,
  (value, features, usesApps, plan) =>
    usesApps
      ? isPositiveNumeric(value)
        ? // one is the min warehouse count regardless of the feature flag
          Math.max(Number(value), 1)
        : Infinity
      : plan === STARTER_PLAN
      ? 1
      : Infinity,
)

export const maxCartCountSelector = createFeatureSelector(
  'max_cart_count',
  companyFeaturesSelector,
  usesAppsSelector,
  planSelector,
  (value, features, usesApps, plan) =>
    usesApps
      ? isPositiveNumeric(value)
        ? Number(value)
        : Infinity
      : plan === STARTER_PLAN
      ? 1
      : Infinity,
)

export const maxUserCountSelector = createFeatureSelector(
  'max_user_count',
  companyFeaturesSelector,
  usesAppsSelector,
  planSelector,
  (value, features, usesApps, plan) =>
    usesApps
      ? isPositiveNumeric(value)
        ? Number(value)
        : Infinity
      : plan === STARTER_PLAN
      ? 1
      : Infinity,
)

export const useVendorConnectSelector = createFeatureSelector(
  'use_vendor_connect',
  companyFeaturesSelector,
  defaultFeatureFunc,
)

export const useAnalyticsSelector = createFeatureSelector(
  'use_analytics',
  companyFeaturesSelector,
  planSelector,
  usesAppsSelector,
  (value, features, plan, usesApps) =>
    usesApps ? !!value : plan !== STARTER_PLAN,
)

export const useAccountingSelector = createFeatureSelector(
  'use_accounting',
  companyFeaturesSelector,
  usesAppsSelector,
  (value, features, usesApps) => (usesApps ? !!value : true),
)

export const useFedExAuthSelector = createFeatureSelector(
  'temp_use_fedex_auth',
  companyFeaturesSelector,
  defaultFeatureFunc,
)

export const underContractSelector = createFeatureSelector(
  'under_contract',
  companyFeaturesSelector,
  defaultFeatureFunc,
)

export const whiteListThemeSelector = createFeatureSelector(
  'white_list_theme',
  companyFeaturesSelector,
  (value) => value || null,
)

export const useOrderRateV3MultiShipperSelector = createFeatureSelector(
  'temp_order_rate_v3_multi_shipper',
  companyFeaturesSelector,
  defaultFeatureFunc,
)

export const shipAppSelector = createSelector(
  useShippingLabelsSelector,
  useRulesSelector,
  useRMALabelsSelector,
  (useShippingLabels, useRules, useRMALabels) =>
    useShippingLabels
      ? useRMALabels
        ? SHIP_APP_PREMIUM
        : useRules
        ? SHIP_APP_ADVANCED
        : SHIP_APP_ESSENTIALS
      : null,
)

export const dropshipAppSelector = createSelector(
  useDropshippingSelector,
  useVendorConnectSelector,
  (useDropshipping, useVendorConnect) =>
    useDropshipping
      ? useVendorConnect
        ? DROPSHIP_APP_PREMIUM
        : DROPSHIP_APP_ADVANCED
      : null,
)

export const inventoryAppSelector = createSelector(
  usesInventorySelector,
  usePurchaseOrdersSelector,
  (usesInventory, usePurchaseOrders) =>
    usesInventory
      ? usePurchaseOrders
        ? INVENTORY_APP_PREMIUM
        : INVENTORY_APP_ADVANCED
      : null,
)

export function getEstimatedAppPrice(shipApp, dropshipApp, inventoryApp) {
  return (
    (APP_PRICES[shipApp] || 0) +
    (APP_PRICES[dropshipApp] || 0) +
    (APP_PRICES[inventoryApp] || 0)
  )
}

export async function getCompany() {
  try {
    const {json} = await api.get('/company/')
    const company = companySelector(getState())

    if (!isEqual(company, json)) {
      setCompany(json)
    }

    return json
  } catch (err) {
    dispatch(
      handlePollingError(
        {
          summary: 'Error fetching company details.',
          details: err.error_message || err.message,
        },
        err,
      ),
    )
  }
}

export async function saveCompany(params) {
  const {json} = await api.put('/company/', params)

  setCompany(json)

  return json
}

export async function enableCompanyFeature(featureFlag) {
  const {json} = await api.post(
    `/company/flags/${encodeURIComponent(featureFlag)}`,
  )
  const company = companySelector(getState())

  setCompany({
    ...company,
    features: json,
  })
}

export async function disableCompanyFeature(featureFlag) {
  const {json} = await api.delete(
    `/company/flags/${encodeURIComponent(featureFlag)}`,
  )
  const company = companySelector(getState())

  setCompany({
    ...company,
    features: json,
  })
}

export async function checkTrustScore() {
  const canBeTrusted = canBeTrustedSelector(getState())
  const hasAccountCreditCard = hasAccountCreditCardSelector(getState())

  if (hasAccountCreditCard && !canBeTrusted) {
    await api.post('/company/request_trust_score')
  }
}

export async function setInitialStateOfBusiness() {
  const warehouse = defaultWarehouseSelector(getState())
  const state = warehouse.address.state
  const company = companySelector(getState())

  if (!STATES[state] || company.state_of_business !== null) {
    return
  }

  await api.put('/company', {state_of_business: state})
}

export async function savePrintNodeConfig(config) {
  const printNodeConfig = printNodeConfigSelector(getState())

  const params = {
    ...printNodeConfig,
    ...config,
  }

  if (!params.default_printer) {
    delete params.default_printer
  }

  await saveCompany({print_node: params})
}
